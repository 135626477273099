export enum LOCAL_TEXT {
  AIRDROP = 'airdrop',
  AIRDROP_TASKS = 'airdrop_tasks',
  ALRIGHT = 'alright',
  AN_UNKNOWN_ERROR_OCCURRED = 'an_unknown_error_occurred',
  APPLICATION_SUBMITTED_SUCCESSFULLY = 'application_submitted_successfully',
  APPLICATION_WAS_SENT_UCCESSFULLY = 'application_was_sent_uccessfully',
  AUTHENTICATION_ERROR = 'authentication_error',
  AUTHENTICATION_FAILED = 'authentication_failed',
  AVALIABLE_BALANCE = 'available_balance',
  BECOME = 'become',
  BECOME_ADVERTISER = 'become_advertiser',
  BRONZE = 'bronze',
  BUY = 'buy',
  CANCEL = 'cancel',
  CLAIM = 'claim',
  CLICK_ON_ME = 'click_on_me',
  CLIPBOARD_COPY_FAILED_USING_FALLBACK_METHOD = 'clipboard_copy_failed_using_fallback_method',
  CHANCE_FOR = 'chance_for',
  COMPLETE_FAST_TASKS = 'complete_fast_tasks',
  COMPLETE_FAST_TASKS_SIMPLE = 'complete_fast_tasks_simple',
  COMPLETE_WAIT = 'complete_wait',
  CONNECT_WALLET = 'connect_wallet',
  CONNECTED = 'connected',
  COPIED = 'copied',
  COPY = 'copy',
  COPY_DESCRIPTION_UPLOAD_VIDEO_YOUR_INSTAGRAM_ACCOUNT = 'copy_description_upload_video_your_instagram_account',
  COPY_LINK_SEND_YOUR_FRIEND = 'copy_link_send_your_friend',
  CREATE_CONTENT = 'create_content',
  DAILY_CHECK_IN = 'daily_check_in',
  DESCRIPTION = 'description',
  DIAMOND = 'diamond',
  DISCONNECT = 'disconnect',
  DISCONNECT_WALLET = 'diconnect_wallet',
  DONE = 'done',
  DOWNLOAD = 'download',
  DOWNLOADING = 'downloading',
  EARN = 'earn',
  EARN_MONEY = 'earn_money',
  EARN_UP_TO_M2E_FOR_EACH_TASKS = 'earn_up_to_1000_M2E_for_each_tasks',
  EDIT_ACCOUNT_INFO = 'edit_account_info',
  ENGLISH = 'english',
  ERROR_CREATED_EVENT = 'error_creating_event',
  ERROR_LOADING_FILE = 'error_loading_file',
  ERROR_LOADING_VIDEO = 'error_loading_video',
  ERROR_NO_EVENTS_FOUND = 'error_no_events_found',
  ERROR_WHILE_OCCURRED_APPLYING_AUTOTASK_COMPLETION = 'error_occurred_while_applying_autotask_completion',
  ERROR_PROCESSING_VIDEO = 'error_processing_video',
  ERROR_SUBMITTING_APPLICATION = 'error_submitting_application',
  EVENT_CREATED_SUCCESSFULLY = 'event_created_successfully',
  FAILED_CREATE_EVENT_AUTHORIZATION_ISSUE = 'failed_create_event_authorization_issue',
  FAILED_SUBMIT_APPLICATION_AUTHORIZATION_ISSUE = 'failed_submit_application_authorization_issue',
  FAILED_TO_SING_IN = 'failed_to_sign_in',
  FALLBACK_COPY_FAILED = 'fallback_copy_failed',
  FAST_TASKS = 'fast_tasks',
  FOLLOW_INSTRUCTIONS = 'follow_instructions',
  FOR_EACH_VIEW_AS_REWARD = 'for_each_view_as_reward',
  FROM_LINK = 'from_link',
  FRIENDS = 'friends',
  GET = 'get',
  GET_REWARD = 'get_reward',
  GET_REVIEW = 'get_review',
  GO_TO = 'go_to',
  GOLD = 'gold',
  HOW_TO_EARN_WITH_US = 'how_to_earn_with_us',
  JOIN_MEME_TOEARN_LINK_IN_ACCOUNT_BIO = 'join_meme_to_earn_link_in_account_bio',
  JOIN_ME_ON_MEME_FACTORY_EARN_TOGETHER = 'join_me_on_meme_factory_earn_together',
  I_HAVE_MY_OWN_CLOTHING_BRAND_CALLED = 'i_have_my_own_clothing_brand_called',
  JOIN_OUR_WHITELIST = 'join_our_whitelist',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  INVESTOR = 'investor',
  INVITE_FRIENDS = 'invite_friends',
  INVITE_FRIENDS_LOWER = 'invite_friends_lower',
  INVITE_FRIENDS_LOWER_IMPERATIVE = 'invite_friends_lower_imperaive',
  LEARN = 'learn',
  LOG_IN_EVERY_DAY_CLAIM_YOUR_REWARD_AND_KEEP_YOUR_STREAK_ALIVE = 'log_in_every_day_claim_your_reward_and_keep_your_streak_alive',
  LOGGED_IN_SUCCESSFULLY = 'logged_in_successfully',
  LOGGING_IN = 'logging_in',
  LOOKS_LIKE_THERE_HISTORY_YET = 'looks_like_there_history_yet',
  MAKE_MONEY = 'make_money',
  MEME_REACHED_VIEWS_GET_REWARD = 'meme_reached_views_get_reward',
  MEME_TO_EARN = 'meme_to_eran',
  MORE = 'more',
  MORE_M2E_POINTS_YOU_GET_MORE_YOUR_AIRDROP_CHANCES = 'more_M2E_points_you_get_more_your_airdrop_chances',
  NOT_FOUND_FILE_ID = 'not_found_file_id',
  NOW_JUST_WAIT_UNTIL_YOUR_MEME_REACH_VIEWS_AND = 'now_just_wait_until_your_meme_reach_views_and',
  NTF_BOUGHT_SUCCESSFULLY = 'ntf_bought_successfully',
  OTHER_WAYS_INCREASE_AIRDROP_CHANCE = 'other_ways_increase_airdrop_chance',
  PARTNER = 'partner',
  PASTE_MEME_URL = 'paste_meme_url',
  PAY = 'pay',
  PLEASE_ENTER_DESCRIPTION = 'please_enter_description',
  PLEASE_ENTER_PRICE = 'please_enter_price',
  PLEASE_ENTER_TITLE = 'please_enter_title',
  PLEASE_ENTER_VALID_PRICE = 'please_enter_valid_price',
  PLEASE_ENTER_URL_VIDEO = 'please_enter_url_video',
  PLEASE_SELECT_FILE = 'please_select_file',
  POST_MEME = 'post_meme',
  POST_MEMES = 'post_memes',
  PRICE = 'price',
  PRICE_DISCOUNT = 'price_discount',
  PROCEED_VIDEO = 'proceed_video',
  PROCESSING_VIDEO = 'processing_video',
  PROVIDE_SOME_INFORMATION_ABOUT_YOUR_PROJECT = 'provide_some_information_about_your_project',
  RE_CREATING_EVENT = 're_creating_event',
  REWARD_CLAIMED_SUCCESSFULLY = 'reward_claimed_successfully',
  RUSSIAN = 'russian',
  SAVE_CHANGES = 'save_changes',
  SELECT_ANOTHER = 'select_another',
  SELECT_LANGUAGE = 'select_language',
  SELECT_LANGUAGE_DOWNLOAD = 'select_language_download',
  SELECT_VIDEO = 'select_video',
  SEND_LINK_MODERATION = 'send_link_moderation',
  SEND_VIDEO_ON_REVIEW = 'send_video_on_review',
  SECURE_YOUR_ACCOUNT_CONNECTING_YOUR_WALLET_START_EARNING_REWARDS = 'secure_your_account_connecting_your_wallet_start_earning_rewards',
  SILVER = 'silver',
  SHARE = 'share',
  SHARE_IN_STORIES = 'share_in_stories',
  SHARE_YOUR_REF_LINK = 'share_your_ref_link',
  SOMETHIMG_WENT_WRONG = 'something_went_wrong',
  SORRY_SOMETHING_WENT_WRONG_WE_WORKING_ON_IT = 'sorry_something_went_wrong_we_working_on_it',
  START_POSTING = 'start_posting',
  SUBMITTING_APPLICATION = 'submitting_application',
  SUCCESSFULLY_CLAIMED = 'successfully_claimed',
  TASKS = 'tasks',
  TASKS_CREATION = 'tasks_creation',
  TITLE = 'title',
  TEXT_INFO = 'text_info',
  TOKENS = 'tokens',
  TOTAL_COUNT = 'total_count',
  TOTAL_PROFIT = 'total_profit',
  UNSUBSCRIBE_WARNING = 'unsubscribe_warning',
  UPLOAD = 'upload',
  UPLOAD_LOCAL = 'upload_local',
  UPLOAD_TO_INSTAGRAM = 'upload_to_instagram',
  UPLOAD_VIDEO = 'upload_video',
  USE_MY_INVITE_LINK_JOIN_FUN = 'use_my_invite_link_join_fun',
  VIDEO_WAS_UPLOADED = 'video_was_uploasded',
  WAIT_UNTIL_GETS_MANY_VIEWS_POSSIBLE_BECAUSE_YOU_CAN_SEND_VIDEO_ONLY = 'wait_until_gets_many_views_possible_because_you_can_send_video_only_once',
  WALLET = 'wallet',
  WATCH_GUIDE = 'watch_guide',
  WELCOME_BONUS = 'welcome_bonus',
  WHAT_MEMO_FACTORY = 'what_meme_factory',
  YOUR_MEME_GETS_VIEWS = 'your_meme_gets_views',
  YOU_GET = 'you_get',
  YOU_GET_REWARD = 'you_get_reward',
  YOU_GET_PER_EACH_FRIEND_HIS_INCOME = 'you_get_per_each_friend_his_income',
  YOU_REWARDED_IMMEDIATELY_WITH_M2E_POINTS_AFTER_EACH_TASK_COMPLETION = 'you_rewarded_immediately_with_M2E_points_after_each_task_completion',
  YOU_REWARDED_IMMEDIATELY_WITH_XP_AFTER_EACH_TASK_COMPLETION = 'you_rewarded_immediately_with_XP_after_each_task_completion',
  YOU_WILL_POST_CONTENT_IN_SOCIALS_AND_EARN_MORE_THAN_M2E_DAILY = 'you_will_post_content_in_socials_and_earn_more_than_M2E_daily',
  YOUR_FRIEND_JOIN = 'your_friend_join',
  YOUR_FRIEND_JOIN_MEME_FACTORY = 'your_friend_join_meme_factory',
}
