export const SORTING_DIRECTIONS = [
  {
    value: 'asc',
    label: 'From cheap',
  },
  {
    value: 'desc',
    label: 'From expensive',
  },
];
